module.exports = {
  SITE_TITLE: 'Matt Ferderer',
  SITE_DESCRIPTION: ' - Learning, Teaching & Developing Software One Bit at a Time',
  DOMAIN: 'https://mattferderer.com',
  TWITTER: 'mattferderer',
  STACKOVERFLOW: 'mattferderer',
  LINKEDIN: 'mattferderer',
  GITHUB: 'mattferderer',
  AUTHOR: `Matt Ferderer`,
  MEDIUM: `mattferderer`,
  REPORTURI: `mattferderer`,
  DISQUS: `mattferderer`,
  BIO: `Software Developer focused on making great user experiences. I enjoy learning, sharing & helping others make amazing things.`,
  AFFILIATELIST: [{
    name: `Pluralsight`,
    // text: `I'm constantly learning from a wide assortment of books & websites. Their library is huge & they have awesome learning paths that help you find your strengths & weaknesses. Check out my <a href="https://app.pluralsight.com/profile/mattferderer">Pluralsight profile</a> to see what I'm learning &amp; how I rank. If you're interested in giving them a try, they offer a <a href="https://pluralsight.pxf.io/c/1197267/424552/7490">10 day free trial</a>.`,
    text: `I'm constantly learning from a wide assortment of books & websites. Their library is huge & they have awesome learning paths that help you find your strengths & weaknesses. If you're interested in giving them a try, they offer a <a href="https://pluralsight.pxf.io/c/1197267/424552/7490">10 day free trial</a>.`,
    tags: [
      `Education`,
      `Learning`,
      `Training`,
      `.NET`,
      `C#`,
      `misc`
    ],
    type: "Education"
  },
    // NEED TO RESIGN UP
  // {
  //   name: `<a href="https://www.anrdoezrs.net/click-9222898-13722493">O'Reilly</a>`,
  //   text: `<p>Want a ridiculously vast personal library of top quality software development content? Try a <a href="https://www.anrdoezrs.net/click-9222898-13722493">Free Trial of O'reilly.</a></p> <ul class="list-disc list-outside mt-2 pl-5">
  //     <li>Digital books in multiple formats</li>
  //     <li>Audio books</li>
  //     <li>Videos</li>
  //     <li>Quality Conference Talks</li>
  //     <li>Interactive Learning Environments</li>
  //     <li>Certification Prep Material</li>
  //     <li>Learning Paths & Personalized Recommendations</li>
  //   </ul>
  //   <p class="mt-2">The amount of books and videos is crazy.</p>
  //   `,
  //   tags: [
  //     `Education`,
  //     `Learning`,
  //     `Training`,
  //     `.NET`,
  //     `C#`,
  //     `misc`
  //   ],
  //   type: "Education"
  // },   
  {
    name: `Brave`,
    text: `Tired of ads tracking your data, making websites slow & unusable? Try the <a href="https://brave.com/mat243">Brave web browser</a>. It's like Google Chrome but it blocks ads & protects your privacy. Brave prevents ads that redirect you to scam websites congratulating you about winning Amazon Gift cards or making you think your device was hacked. You can even view recipe websites without your device crashing. <a href="https://brave.com/mat243">Download Brave</a> for your desktop, laptop, tablet & phone!`,
    tags: [
      `Browser`,
      `Security`,
      `misc`
    ],
    type: "Browser"
  },
  // {
  //   name: `Office 365`,
  //   text: `I never thought I'd leave my free GSuite E-mail service as I was grandfathered in to the free price. <a href="https://microsoft-us.evyy.net/c/1197267/190407/3327">Microsoft's Office 365</a> has been on a roll lately though that makes it to good to say no to. For the price of a couple coffees a month, you get: <br />
  //     E-mail hosting for your domain,<br />
  //     Full Office suite (online & on your device),<br />
  //     Teams (chats to replace Slack),<br />
  //     Planner (task management to replace Trello/Asana),<br />
  //     SharePoint & a ton of other stuff!`,

  //   tags: [
  //     `Business`,
  //     `Email`,
  //   ]
  // },
  {
    name: `Audible`,
    text: `If you haven't tried Audiobooks yet you're missing out on one of my absolute favorite learning tools. I rarely have time to sit down & read a book but I can find time to listen to books at the gym, driving, walking my dogs & doing work around my home. <a target="_blank" href="https://www.amazon.com/Audible-Free-Trial-Digital-Membership/dp/B00NB86OYE/?ref_=assoc_tag_ph_1485906643682&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=mf4209-20&linkId=44f74b6be166844a153610ea8ac318d5">Try Audible and Get Two Free Audiobooks</a>. If you don't like it, get rid of it. If you get addicted, you're welcome! Check out my list of <a href="/favorite-books">favorite books</a> for some recommendations!`,

    tags: [
      `Books`,
      `Learning`,
      `Software`,
      `misc`
    ],
    type: "Audiobooks"
  },
  {
    name: `Thinking About Freelancing or Consulting?`,
    text: `The biggest financial mistake I ever made was being too cheap to hire a tax accountant & purchase accounting software. Quit wasting your time trying to build your own version or keeping track in a spreadsheet. Hire a tax accountant & buy yourself something like <a href="https://shareasale.com/r.cfm?b=1096032&u=1522841&m=52946&urllink=&afftrack=">FreshBooks</a> or <a href="http://fbuy.me/k1ePE">Quickbooks</a> to organize expenses, track your time, send invoices & accept payments. They pay for themselves, save you a butt load of time & keep you from looking like an unorganized mess.`,
    tags: [
      `Consulting`,
      `Freelance`,
      `Startup`,
      `misc`
    ],
    type: "Accounting"
  },
    // {
    //   name: ``,
    //   text: ``,
    //   tags: [
    //     ``
    //   ]
    // },
  ]
}
