import * as React from "react"
import { Link } from "gatsby"
import "./style.css"
import { DarkModeToggle } from "../DarkModeToggle"

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  return (
    <header className="header-component">
      <nav className="md:pl-2">
        <ul className="flex flex-wrap max-w-6xl 3xl:max-w-screen-2xl m-auto w-full">
          {props.tagList.map(({ url, title }, i) => (
            <li
              className="menu-link"
              key={i}>
              <Link
                className="
                px-5
                py-4
                block
                hover:underline
                text-white"
                to={url}>{title}</Link></li>
          ))}
          <li className="
                px-5
                py-4
                block
                flex-grow
                text-right">
            <DarkModeToggle darkModeToggleHandler={props.darkModeToggleHandler} darkMode={props.darkMode} />
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.displayName = "Header"

export default Header

interface HeaderProps {
  tagList: HeaderTagList[]
  darkMode: boolean,
  darkModeToggleHandler: () => void,
}

interface HeaderTagList {
  url: string,
  title: string,
}
