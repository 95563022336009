import * as React from "react"
import { Link } from "gatsby"
import "./style.css"

const year = new Date().getFullYear()

export const Footer: React.SFC<FooterProps> = ({ title }) => (
    <footer className="bg-gray-900 text-white pt-6 pb-8">
        <section className="lg:max-w-6xl 3xl:max-w-screen-2xl w-full m-auto relative px-8">
            <div>
                <Link to="/">{title}</Link> © 2014 - {year}
                <p className="">
                Licensed under <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0</a>.
                </p>
            </div>
            <p className="italic w-full">
            I encourage you to copy &amp; build upon the material on this website to teach others &amp; learn by teaching! If you found this site helpful, please link back to it.
            </p>
        </section>
    </footer>
)

Footer.displayName = "Footer"

export default Footer

export interface FooterProps {
    title: string,
}
