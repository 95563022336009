import * as React from "react"
import Helmet from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import * as config from "../constants"
// import "../css/style.css"
// import "prismjs/themes/prism.css"
// import "../css/dark-prism.css"

const menuLinks = [
  { url: "/", title: "Home" },
  { url: "/tag/net", title: ".NET" },
  { url: "/tag/javascript", title: "JavaScript" },
  { url: "/tag/security", title: "Security" },
  { url: "/favorite-books", title: "Favorite Books" },
]

export const TemplateWrapper: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = React.useState(true);
  const [hasBeenClicked, setHasBeenClicked] = React.useState(false);

  const darkModeToggleHandler = React.useCallback(() => {
    const newDarkMode = !darkMode;
    setAndStoreDarkMode(newDarkMode);
    setHasBeenClicked(true);
  }, [setDarkMode, darkMode])


  const getStoredDarkMode = () => {
    var localStorageValue = typeof localStorage !== 'undefined' && localStorage.getItem('darkMode')
    if (localStorageValue === null) {
      return null;
    }
    return localStorageValue === "true"
  };

  const setAndStoreDarkMode = (newDarkMode: boolean) => {
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", (newDarkMode).toString());
  }

  React.useEffect(() => {
    let initial_isDarkMode: boolean = false;
    const storedDarkMode = getStoredDarkMode();

    if (storedDarkMode === null) {
      initial_isDarkMode = getComputedStyle(document.querySelector('.hero-wrapper'))
        .getPropertyValue('--display-moon') === 'block';
    } else {
      initial_isDarkMode = storedDarkMode;
    }

    // By default we display dark mode. If we determine they prefer light mode
    // and storedDarkMode is null (which means this is a page load probably)
    // we than want a nice transition to light mode. So we start in dark mode
    // than switch to light mode with a sunrise.
    if (initial_isDarkMode === false && storedDarkMode === null) {
      setAndStoreDarkMode(true);
      const timer = setTimeout(darkModeToggleHandler, 2000);
    } else {
      // If the above is not true, we just set what they prefer & avoid any transition
      setAndStoreDarkMode(initial_isDarkMode);
    }

  }, [])

  return (
    <div>
      <Helmet
        link={[
          // {
          //   rel: "stylesheet",
          //   type: "text/css",
          //   href:
          //     "/style.css",
          // },
          // {
          //   rel: "stylesheet",
          //   type: "text/css",
          //   href:
          //     "//cdnjs.cloudflare.com/ajax/libs/prism/1.14.0/themes/prism.min.css",
          // },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png"
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png"
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png"
          },
          {
            rel: "manifest",
            href: "/site.webmanifest"
          },
          {
            rel: "mask-icon",
            href: "/safari-pinned-tab.svg",
            color: "#5bbad5"
          },
        ]}
        meta={[
          { name: "HandheldFriendly", content: "True" },
          { name: "description", content: config.SITE_DESCRIPTION },
          { property: "og:site_name", content: config.SITE_TITLE },
          { property: "og:type", content: "website" },
          { property: "og:title", content: config.SITE_TITLE },
          { property: "og:description", content: config.SITE_DESCRIPTION },
          { property: "og:url", content: config.DOMAIN },
          { name: "twitter:card", content: "summary" },
          { name: "twitter:title", content: config.SITE_TITLE },
          { name: "twitter:description", content: config.SITE_DESCRIPTION },
          { name: "twitter:url", content: config.DOMAIN },
          { name: "twitter:site", content: "@" + config.TWITTER },
          { name: "msapplication-TileColor", content: "#00aba9" },
          { name: "theme-color", content: "#ffffff" },
          { name: "ahrefs-site-verification", content: "5db91549d22dedf0ecc2e44125339010d6741c2af73bc7e7cd17bf01523775fc" },
        ]}
      >
        <html lang="en" className={`${darkMode === false ? 'light' : ''}${darkMode === true ? 'dark' : ''}`} />
        <body className=" dark:bg-gray-800 bg-gray-50" />
      </Helmet>
      <Hero darkModeToggleHandler={darkModeToggleHandler} darkMode={darkMode} hasBeenClicked={hasBeenClicked} />
      <Header tagList={menuLinks} darkMode={darkMode} darkModeToggleHandler={darkModeToggleHandler} />
      <main id="content" className=" dark:bg-gray-800 bg-gray-50 pt-1 text-black dark:text-white
" role="main">
        <div className="lg:flex lg:max-w-6xl 3xl:max-w-screen-2xl w-full m-auto relative px-2 md:px-8 lg:px-0">
          {children}
        </div>
      </main>
      <Footer title={config.SITE_TITLE} />
    </div>
  )
}

export default TemplateWrapper