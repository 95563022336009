import * as React from "react"
import "./hero.css"
import "../HeaderSvg/style.css"
import HeaderSvg from "../HeaderSvg"

export const Hero: React.FC<{darkModeToggleHandler: () => void, darkMode: boolean, hasBeenClicked: boolean}> = (props) => {
  // const [darkMode, setDarkMode] = React.useState(props.darkMode);
  // const toggleDarkMode = React.useCallback(() => {
  //   setDarkMode(!darkMode);
  //   setHasBeenClicked(true);
  //   props.darkModeToggleHandler(!darkMode);
  // }, [setDarkMode, darkMode])
  return (
    <div className={`hero-wrapper relative overflow-hidden`} onClick={props.darkModeToggleHandler}>
      <HeaderSvg darkMode={props.darkMode} hasBeenClicked={props.hasBeenClicked} />
    </div>
  )
}
export default Hero